@import url('https://fonts.googleapis.com/css?family=Roboto|Open+Sans');

html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  position: relative;
  height: 100%;
  width: 100%;
}
