@import url(https://fonts.googleapis.com/css?family=Roboto|Open+Sans);
html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

#root {
  position: relative;
  height: 100%;
  width: 100%;
}

/*Translation - In*/
@keyframes fromTopLeft-in {
  from {
    margin-top: calc(var(--marginTranslationIn) * -1);
    margin-left: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromUp-in {
  from {
    margin-top: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromTopRight-in {
  from {
    margin-top: calc(var(--marginTranslationIn) * -1);
    margin-left: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
@keyframes fromLeft-in {
  from {
    margin-left: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromRight-in {
  from {
    margin-left: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
@keyframes fromBottomLeft-in {
  from {
    margin-top: var(--marginTranslationIn);
    margin-left: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromDown-in {
  from {
    margin-top: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
@keyframes fromBottomRight-in {
  from {
    margin-top: var(--marginTranslationIn);
    margin-left: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
/*Translation - Out*/
@keyframes toTopLeft-out {
  from {
    margin: 0;
  }
  to {
    margin-top: calc(var(--marginTranslationOut) * -1);
    margin-left: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toUp-out {
  from {
    margin: 0;
  }
  to {
    margin-top: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toTopRight-out {
  from {
    margin: 0;
  }
  to {
    margin-top: calc(var(--marginTranslationOut) * -1);
    margin-left: var(--marginTranslationOut);
  }
}
@keyframes toLeft-out {
  from {
    margin: 0;
  }
  to {
    margin-left: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toRight-out {
  from {
    margin: 0;
  }
  to {
    margin-left: var(--marginTranslationOut);
  }
}
@keyframes toBottomLeft-out {
  from {
    margin: 0;
  }
  to {
    margin-top: var(--marginTranslationOut);
    margin-left: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toDown-out {
  from {
    margin: 0;
  }
  to {
    margin-top: var(--marginTranslationOut);
  }
}
@keyframes toBottomRight-out {
  from {
    margin: 0;
  }
  to {
    margin-top: var(--marginTranslationOut);
    margin-left: var(--marginTranslationOut);
  }
}
/*Fade*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes cross-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes cross-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes fade-out-in-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out-in-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*None*/
@keyframes none-in {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes none-out {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/*Last slide*/
@keyframes last-slide-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/*Blink*/
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: var(--minOpacity);
  }
  100% {
    opacity: 1;
  }
}

.canvas-for-eyedropper {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
}

.background-for-canvas {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  z-index: 1110;
  pointer-events: none;
}

.cancel-click-zone {
  pointer-events: auto;
  background: transparent;
  position: absolute;
}

/*Fade*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes cross-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes cross-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes fade-out-in-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out-in-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*None*/
@keyframes none-in {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes none-out {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/*Last slide*/
@keyframes last-slide-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

#root {
  /* z-index: 4000; */
}
.banner {
  /* z-index: 4000; */
  position: absolute;
  display: block;
  overflow: hidden;
  cursor: pointer;
}

.slide {
  animation-iteration-count: 1, 1;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-fill-mode: both, forwards;
  animation-direction: normal, normal;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.element {
  position: absolute;
  display: block;
  z-index: 0;
  box-sizing: border-box;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards, forwards;
  animation-direction: normal;
}

.element * {
  box-sizing: border-box;
}

.text-container {
  display: table;
}

.text {
  width: 100%;
  display: table-cell;
}

.rectangle {
  height: 100%;
  width: 100%;
  border-style: solid;
}

.button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  padding: 0;
  cursor: pointer;
  pointer-events: auto;
  transition: background-image 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.button:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  transition: background-image 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.button:hover::after {
  background-color: rgba(0, 0, 0, 0.1);
}
.button.text {
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
}
.button.verticalGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2)
  );
}
.button.horizontalGradient {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2)
  );
}
.button.diagonalGradient {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2)
  );
}
.button.glossy {
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.12) 50%,
    rgba(0, 0, 0, 0.04)
  );
}

.image {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}

