#root {
  /* z-index: 4000; */
}
.banner {
  /* z-index: 4000; */
  position: absolute;
  display: block;
  overflow: hidden;
  cursor: pointer;
}

.slide {
  animation-iteration-count: 1, 1;
  animation-timing-function: ease-in-out, ease-in-out;
  animation-fill-mode: both, forwards;
  animation-direction: normal, normal;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.element {
  position: absolute;
  display: block;
  z-index: 0;
  box-sizing: border-box;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  animation-fill-mode: backwards, forwards;
  animation-direction: normal;
}

.element * {
  box-sizing: border-box;
}

.text-container {
  display: table;
}

.text {
  width: 100%;
  display: table-cell;
}

.rectangle {
  height: 100%;
  width: 100%;
  border-style: solid;
}

.button {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  padding: 0;
  cursor: pointer;
  pointer-events: auto;
  transition: background-image 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.button:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: inherit;
  transition: background-image 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.button:hover::after {
  background-color: rgba(0, 0, 0, 0.1);
}
.button.text {
  width: 100%;
  white-space: pre-wrap;
  text-align: center;
}
.button.verticalGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2)
  );
}
.button.horizontalGradient {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2)
  );
}
.button.diagonalGradient {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.2)
  );
}
.button.glossy {
  box-shadow: inset 0 1px rgba(255, 255, 255, 0.4), 0 1px 2px rgba(0, 0, 0, 0.2);
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0) 50%,
    rgba(0, 0, 0, 0.12) 50%,
    rgba(0, 0, 0, 0.04)
  );
}

.image {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}
