/*Fade*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes cross-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes cross-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes fade-out-in-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out-in-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*None*/
@keyframes none-in {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes none-out {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/*Last slide*/
@keyframes last-slide-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
