.canvas-for-eyedropper {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: auto;
}

.background-for-canvas {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  z-index: 1110;
  pointer-events: none;
}

.cancel-click-zone {
  pointer-events: auto;
  background: transparent;
  position: absolute;
}
