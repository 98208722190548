/*Translation - In*/
@keyframes fromTopLeft-in {
  from {
    margin-top: calc(var(--marginTranslationIn) * -1);
    margin-left: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromUp-in {
  from {
    margin-top: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromTopRight-in {
  from {
    margin-top: calc(var(--marginTranslationIn) * -1);
    margin-left: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
@keyframes fromLeft-in {
  from {
    margin-left: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromRight-in {
  from {
    margin-left: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
@keyframes fromBottomLeft-in {
  from {
    margin-top: var(--marginTranslationIn);
    margin-left: calc(var(--marginTranslationIn) * -1);
  }
  to {
    margin: 0;
  }
}
@keyframes fromDown-in {
  from {
    margin-top: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
@keyframes fromBottomRight-in {
  from {
    margin-top: var(--marginTranslationIn);
    margin-left: var(--marginTranslationIn);
  }
  to {
    margin: 0;
  }
}
/*Translation - Out*/
@keyframes toTopLeft-out {
  from {
    margin: 0;
  }
  to {
    margin-top: calc(var(--marginTranslationOut) * -1);
    margin-left: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toUp-out {
  from {
    margin: 0;
  }
  to {
    margin-top: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toTopRight-out {
  from {
    margin: 0;
  }
  to {
    margin-top: calc(var(--marginTranslationOut) * -1);
    margin-left: var(--marginTranslationOut);
  }
}
@keyframes toLeft-out {
  from {
    margin: 0;
  }
  to {
    margin-left: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toRight-out {
  from {
    margin: 0;
  }
  to {
    margin-left: var(--marginTranslationOut);
  }
}
@keyframes toBottomLeft-out {
  from {
    margin: 0;
  }
  to {
    margin-top: var(--marginTranslationOut);
    margin-left: calc(var(--marginTranslationOut) * -1);
  }
}
@keyframes toDown-out {
  from {
    margin: 0;
  }
  to {
    margin-top: var(--marginTranslationOut);
  }
}
@keyframes toBottomRight-out {
  from {
    margin: 0;
  }
  to {
    margin-top: var(--marginTranslationOut);
    margin-left: var(--marginTranslationOut);
  }
}
/*Fade*/
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes cross-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes cross-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*Cross-Fade*/
@keyframes fade-out-in-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out-in-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
/*None*/
@keyframes none-in {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes none-out {
  0% {
    opacity: 1;
  }
  1% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
/*Last slide*/
@keyframes last-slide-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/*Blink*/
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: var(--minOpacity);
  }
  100% {
    opacity: 1;
  }
}
